import * as React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

const NotFoundPage = () => {
  const Heading = "404";
  return (
    <div className="four-o-four-container">
      <Helmet>
        <body className="body"/>
        <title> {Heading} </title>
      </Helmet>
      <div className="four-o-four-header" >
        <h1> Page Not Found </h1>
      </div>
      <StaticImage src="../images/psyduck-no-bg.png" alt="¯\_(ツ)_/¯"/>
    </div>
  )
}

export default NotFoundPage
